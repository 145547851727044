import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg';

// Custom Hook for fetching workshop data
const useWorkshopData = (tlMappings) => {
  const [workshopData, setWorkshopData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const isWorkshopCompleted = (endDate) => new Date(endDate) < new Date();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const labels = Object.values(tlMappings);
        const workshopCounts = Array(labels.length).fill(0);

        const fetchWorkshopData = async (TL) => {
          const response = await axiosApiService.get('/training/workshops/', {
            headers: { TL },
          });
          return response.data;
        };

        await Promise.all(
          Object.entries(tlMappings).map(async ([TL, name], index) => {
            try {
              const workshops = await fetchWorkshopData(name);
              const completed = workshops.filter((ws) =>
                isWorkshopCompleted(ws.to_date)
              ).length;
              workshopCounts[index] = completed;
            } catch (err) {
              console.error(`Error fetching data for TL ${name}:`, err);
              workshopCounts[index] = 0;
            }
          })
        );

        setLabels(labels);
        setWorkshopData(workshopCounts);
      } catch (err) {
        console.error('Error fetching workshop data:', err);
        setError('Failed to load workshop data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tlMappings]);

  return { workshopData, labels, isLoading, error };
};

// Reusable Chart Component
const ChartComponent = ({ labels, data, options }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!labels.length || !data.length) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Number of Workshops',
            data,
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
          },
        ],
      },
      options: options || {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            max: Math.ceil(Math.max(...data) * 1.2) || 10,
            title: { display: true, text: 'Number of Workshops' },
            ticks: { stepSize: 1 },
          },
        },
      },
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [labels, data, options]);

  return <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

// Loading Indicator Component
const LoadingIndicator = ({ height }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height={height}
    width="100%"
  >
    <CircularProgress />
  </Box>
);

// Error Message Component
const ErrorMessage = ({ message, height }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={height}
    width="100%"
  >
    <Typography variant="h6" color="error">
      {message}
    </Typography>
  </Box>
);

// No Data Component
const NoData = ({ height }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={height}
    width="100%"
  >
    <img
      src={nodatafoundImage}
      alt="No Data Found"
      style={{ width: '200px', marginBottom: '16px' }}
    />
    <Typography variant="h5" color="textSecondary">
      No data available
    </Typography>
  </Box>
);

// Enlarged Chart Dialog Component
const EnlargedChartDialog = ({ labels, workshopData, open, handleClose }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth="lg"
    aria-labelledby="workshops-chart-dialog-title"
    sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}
  >
    <DialogTitle id="workshops-chart-dialog-title">
      Workshops Completed - Detailed View
    </DialogTitle>
    <DialogContent>
      {workshopData.length > 0 && Math.max(...workshopData) > 0 ? (
        <Box
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ChartComponent labels={labels} data={workshopData} />
        </Box>
      ) : (
        <NoData height="500px" />
      )}
    </DialogContent>
  </Dialog>
);

// Main WorkshopsChart Component
const WorkshopsChart = ({ tlMappings }) => {
  const { workshopData, labels, isLoading, error } = useWorkshopData(tlMappings);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const hasData = workshopData.length > 0 && Math.max(...workshopData) > 0;

  return (
    <>
      <Card
        sx={{
          borderRadius: '24px',
          backgroundColor: '#f9fafe',
          borderColor: '#d3d3d3',
          borderWidth: 1,
          borderStyle: 'solid',
          width: '90%',
          height: 336,
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <CardContent sx={{ width: '100%' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            mt={-3}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#212121',
                textAlign: 'center',
                letterSpacing: 0,
              }}
            >
              Workshops Completed
            </Typography>
            <IconButton onClick={handleOpen} aria-label="Expand chart">
              <ExpandMoreIcon sx={{rotate:"-135deg",}} />
            </IconButton>
          </Box>

          {isLoading ? (
            <LoadingIndicator height="250px" />
          ) : error ? (
            <ErrorMessage message={error} height="250px" />
          ) : hasData ? (
            <Box
              sx={{
                height: '300px',
                width: '100%',
                marginTop: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ChartComponent labels={labels} data={workshopData} />
            </Box>
          ) : (
            <NoData height="250px" />
          )}
        </CardContent>
      </Card>

      {/* Enlarged Chart Dialog */}
      <EnlargedChartDialog
        labels={labels}
        workshopData={workshopData}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default WorkshopsChart;
