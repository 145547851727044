import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Box, Typography, CircularProgress, Dialog } from "@mui/material";
import AddMachineDialog from "../Popups/AddMachine"; // Import updated AddMachineDialog

const BarcodeScanner = () => {
  const [data, setData] = useState("Not Found");
  const [loading, setLoading] = useState(false);
  const [upcData, setUPCData] = useState(null); // Store fetched UPC data
  const [dialogOpen, setDialogOpen] = useState(false); // Control dialog visibility

  // Array of dummy datasets
  const dummyUPCDatasets = [
    {
      id: 1,
      name: "Excavator Model X",
      upc: 123456789012,
      manufacturer: "Excavator Co.",
      description: "High-performance excavator designed for heavy-duty operations.",
      availability: "High",
      status: "Operational",
      category: "Construction Machinery",
      location: "Warehouse 3",
      instances: 5,
      purchase_cost: 50000,
      image: null,
      supervised: true,
    },
    {
      id: 2,
      name: "Bulldozer Model Y",
      upc: 234567890123,
      manufacturer: "Bulldozer Inc.",
      description: "Heavy-duty bulldozer for construction sites.",
      availability: "Medium",
      status: "Under Maintenance",
      category: "Construction Machinery",
      location: "Warehouse 2",
      instances: 3,
      purchase_cost: 75000,
      image: null,
      supervised: false,
    },
    {
      id: 3,
      name: "Crane Model Z",
      upc: 345678901234,
      manufacturer: "Crane Masters",
      description: "Tower crane for high-rise construction projects.",
      availability: "Low",
      status: "Operational",
      category: "Construction Equipment",
      location: "Warehouse 1",
      instances: 2,
      purchase_cost: 100000,
      image: null,
      supervised: true,
    },
    {
      id: 4,
      name: "Forklift Model A",
      upc: 456789012345,
      manufacturer: "Lift Co.",
      description: "Forklift for warehouse logistics.",
      availability: "High",
      status: "Operational",
      category: "Logistics Equipment",
      location: "Warehouse 4",
      instances: 10,
      purchase_cost: 25000,
      image: null,
      supervised: false,
    },
  ];

  let dataIndex = 0; // Keeps track of which dummy dataset to use

  const handleBarcodeUpdate = (barcode) => {
    setLoading(true);

    // Cycle through dummy datasets
    setTimeout(() => {
      console.log("Simulated UPC data fetched for barcode:", barcode);
      const selectedData = dummyUPCDatasets[dataIndex];
      setUPCData(selectedData); // Set the selected dataset as UPC data
      setDialogOpen(true); // Open the dialog
      setTimeout(() => setData("Loading"), 1000);
      setLoading(false);

      // Update the index to cycle to the next dataset
      dataIndex = (dataIndex + 1) % dummyUPCDatasets.length;
    }, 1000); // Simulate API delay
  };

  return (
    <Box sx={{ textAlign: "center", p: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Scan a Barcode
      </Typography>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) {
            setData(result.text);
            handleBarcodeUpdate(result.text); // Fetch dummy UPC data
          } else {
            setData("Not Found");
          }
        }}
      />
      <Typography variant="body1" sx={{ mt: 2 }}>
        Scanned Barcode: {data}
      </Typography>
      {loading && <CircularProgress sx={{ mt: 2 }} />}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
        <AddMachineDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          initialData={upcData} // Pass the selected UPC data as initial data
          update={() => {}}
          setUpdate={() => {}}
          flag={false}
          setFlag={() => {}}
        />
      </Dialog>
    </Box>
  );
};

export default BarcodeScanner;
