import React, { useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { axiosApiService } from "../../../utilities/api/axios";
import nodatafoundImage from "../../../utilities/dummy_assets/nodatafound.jpg";

// Custom Hook for fetching Cost vs Issue data
const useCostVSIssueData = (tlMappings) => {
  const [bubbleData, setBubbleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCostVSIssueData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const currentDate = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);

        const tlData = await Promise.all(
          Object.entries(tlMappings).map(async ([tlId, tlName]) => {
            try {
              const [usageResponse, costResponse] = await Promise.all([
                axiosApiService.get("/machine_reservation_list/", {
                  headers: { TL: tlName },
                }),
                axiosApiService.get("/admin_machine_list/", {
                  headers: { TL: tlName },
                }),
              ]);

              // Filter reservations approved in the last 7 days, considering both date and time
              const recentApprovedReservations = usageResponse.data.filter(
                (reservation) => {
                  const reservationStartTime = new Date(reservation.start_time);
                  return (
                    reservation.approved_status === "1" &&
                    reservationStartTime >= sevenDaysAgo &&
                    reservationStartTime <= currentDate
                  );
                }
              );

              // Calculate total hours from the filtered reservations
              const totalHours = recentApprovedReservations.reduce(
                (acc, curr) => acc + curr.duration,
                0
              );

              // Filter machines related to recent approved reservations with purchase_cost > 0
              const recentMachineIds = recentApprovedReservations.map(
                (res) => res.machine
              );

              const totalCost = costResponse.data
                .filter(
                  (machine) =>
                    recentMachineIds.includes(machine.id) &&
                    parseFloat(machine.purchase_cost || 0) > 0
                )
                .reduce(
                  (acc, curr) => acc + parseFloat(curr.purchase_cost || 0),
                  0
                );

              return {
                tlId,
                tlName,
                totalHours,
                totalCost,
                backgroundColor: `rgba(${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )}, 0.6)`,
              };
            } catch (tlError) {
              console.error(`Error fetching data for TL ${tlName}:`, tlError);
              return {
                tlId,
                tlName,
                totalHours: 0,
                totalCost: 0,
                backgroundColor: "rgba(0, 0, 0, 0.6)", // Default color on error
              };
            }
          })
        );

        // Check if all totalCosts are zero
        const isTotalCostZero = tlData.every((tl) => tl.totalCost === 0);

        if (isTotalCostZero) {
          setBubbleData([]); // No data to display
          return;
        }

        const totalHoursAllTLs = tlData.reduce(
          (acc, curr) => acc + curr.totalHours,
          0
        );

        const combinedData = tlData.map((tl) => ({
          label: tl.tlName,
          x: tl.totalCost,
          y: tl.totalHours,
          r:
            totalHoursAllTLs > 0
              ? (tl.totalHours / totalHoursAllTLs) * 100
              : 10, // Dynamic radius with a fallback
          backgroundColor: tl.backgroundColor,
        }));

        setBubbleData(combinedData);
      } catch (err) {
        console.error("Error fetching bubble chart data:", err);
        setError("Failed to load bubble chart data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCostVSIssueData();
  }, [tlMappings]);

  return { bubbleData, isLoading, error };
};

// Reusable Chart Component
const ChartComponent = ({ type, datasets, options }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!datasets || datasets.length === 0) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    chartInstanceRef.current = new Chart(ctx, {
      type,
      data: {
        datasets: datasets,
      },
      options: options,
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [type, datasets, options]);

  return <canvas ref={chartRef} style={{ width: "100%", height: "100%" }} />;
};

// Loading Indicator Component
const LoadingIndicator = ({ height }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height={height}
    width="100%"
  >
    <CircularProgress />
  </Box>
);

// Error Message Component
const ErrorMessage = ({ message, height }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={height}
    width="100%"
  >
    <Typography variant="h6" color="error">
      {message}
    </Typography>
  </Box>
);

// No Data Component
const NoData = ({ height }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={height}
    width="100%"
  >
    <img
      src={nodatafoundImage}
      alt="No Data Found"
      style={{ width: "200px", marginBottom: "16px" }}
    />
    <Typography variant="h5" color="textSecondary">
      No data available
    </Typography>
  </Box>
);

// Enlarged Chart Dialog Component
const EnlargedChartDialog = ({ bubbleData, open, handleClose }) => {
  const dialogOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        max: Math.max(...bubbleData.map((data) => data.x)) * 1.5 || 10,
        title: {
          display: true,
          text: "Total Cost of Machines",
        },
      },
      y: {
        beginAtZero: true,
        max: Math.max(...bubbleData.map((data) => data.y)) * 1.5 || 10,
        title: {
          display: true,
          text: "Number of Hours",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const x = context.parsed.x;
            const y = context.parsed.y;
            return `${label}: Cost = ${x}, Hours = ${y}`;
          },
        },
      },
    },
  };

  const datasets = bubbleData.map((item) => ({
    label: item.label,
    data: [
      {
        x: item.x,
        y: item.y,
        r: item.r,
      },
    ],
    backgroundColor: item.backgroundColor,
  }));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="cost-vs-issue-chart-dialog-title"
    >
      <DialogTitle id="cost-vs-issue-chart-dialog-title">
        COST V/S USE - Detailed View
      </DialogTitle>
      <DialogContent>
        {bubbleData.length > 0 &&
        bubbleData.some((item) => item.x > 0 || item.y > 0) ? (
          <Box
            sx={{
              height: "500px", // Increased height for larger chart
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ChartComponent
              type="bubble"
              datasets={datasets}
              options={dialogOptions}
            />
          </Box>
        ) : (
          <NoData height="500px" />
        )}
      </DialogContent>
    </Dialog>
  );
};

// Main CostVSIssueChart Component
const CostVSIssueChart = ({ tlMappings }) => {
  const { bubbleData, isLoading, error } = useCostVSIssueData(tlMappings);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const hasData =
    bubbleData.length > 0 &&
    bubbleData.some((item) => item.x > 0 || item.y > 0);

  // Define chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        max: Math.max(...bubbleData.map((data) => data.x)) * 1.5 || 10,
        title: {
          display: true,
          text: "Total Cost of Machines",
        },
      },
      y: {
        beginAtZero: true,
        max: Math.max(...bubbleData.map((data) => data.y)) * 1.5 || 10,
        title: {
          display: true,
          text: "Number of Hours",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const x = context.parsed.x;
            const y = context.parsed.y;
            return `${label}: Cost = ${x}, Hours = ${y}`;
          },
        },
      },
    },
  };

  // Prepare datasets for the main chart
  const mainChartDatasets = bubbleData.map((item) => ({
    label: item.label,
    data: [
      {
        x: item.x,
        y: item.y,
        r: item.r,
      },
    ],
    backgroundColor: item.backgroundColor,
  }));

  return (
    <>
      <Card
        sx={{
          borderRadius: "24px",
          backgroundColor: "#f9fafe",
          borderColor: "#d3d3d3",
          borderWidth: 1,
          borderStyle: "solid",
          height: "351px",
          width: "95%",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative", // To position the IconButton absolutely
        }}
      >
        <CardContent sx={{ width: "100%" }}>
          {/* Header with Title and Expand Icon */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={-2}
            // mb={2} // Margin bottom for spacing
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#212121",
                textAlign: "center",
                letterSpacing: 0,
              }}
            >
              COST V/S USE
            </Typography>
            {/* Expand Icon Button */}
            <IconButton onClick={handleOpen} aria-label="Expand chart">
              <ExpandMoreIcon sx={{rotate:"-135deg",}} />
            </IconButton>
          </Box>

          {/* Conditional Rendering of Chart, Loading, Error, or No Data */}
          {isLoading ? (
            <LoadingIndicator height="320px" />
          ) : error ? (
            <ErrorMessage message={error} height="320px" />
          ) : hasData ? (
            <Box
              sx={{
                height: "380px",
                width: "100%",
                marginTop: "-40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ChartComponent
                type="bubble"
                datasets={mainChartDatasets}
                options={chartOptions}
              />
            </Box>
          ) : (
            <NoData height="320px" />
          )}
        </CardContent>
      </Card>

      {/* Enlarged Chart Dialog */}
      <EnlargedChartDialog
        bubbleData={bubbleData}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default CostVSIssueChart;
