import PushNotificationAPIs from "./utilities/api/pushNotification/pushNotificationAPIs";

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function askNotificationPermission() {
    return new Promise((resolve, reject) => {
        const permissionResult = Notification.requestPermission((result) => {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    });
}

export async function subscribeUserToPush() {
    if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.ready; // Wait for active service worker
        const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BHeCfCeIIfNi191gYKEU0enYNdEXZbpxDsUxw2kGdptU4Ti0XlHlAImLDAfHp83NlYUM6uWEZrbpOZN23gGnOh4')
        };

        const subscription = await registration.pushManager.subscribe(subscribeOptions);
        // console.log('PushSubscription:', JSON.stringify(subscription));
        // console.log('PushSubscription:', JSON.stringify(subscription));
        // return JSON.stringify(subscription);
        return subscription;
    } else {
        throw new Error('Service Worker or PushManager not supported.');
    }
}

export async function sendSubscriptionToBackend(subscription,user) {
    // console.log('Sending subscription to server in JSON.stringify format:', JSON.stringify(subscription));
    console.log('Sending subscription to server in direct format:', subscription);
    try {
        subscription = JSON.parse(JSON.stringify(subscription));
        const payload = {
            endpoint: subscription.endpoint,
            auth: subscription.keys.auth,
            p256dh: subscription.keys.p256dh,
            // user: user.id,
            // TL: user.TL,
        };
        // const payload = JSON.stringify(subscription);
        // const payload = {subscription};

        // console.log('Sending subscription payload:', payload);

        const response = await PushNotificationAPIs.SubscribeUserDevice(payload);
        console.log('Subscription saved on the server:', response);
        return response;
    } catch (error) {
        console.error('Error sending subscription to server:', error.response?.data || error.message);
        throw error;
    }
}
