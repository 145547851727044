// utilities/api/IMS/ChatWebSocketService.js
class ChatWebSocketService {
    constructor() {
      this.socket = null;
      this.messageCallback = null;
      this.senderUsername = null;
    }
  
    connect(accessToken, receiverUsername, senderUsername) {
      // If there's an existing chat socket, close it before opening a new one
      if (this.socket) {
        this.socket.close();
      }
      this.senderUsername = senderUsername;
  
      const url = `wss://sys.staging.mis.mapit.ai/ws/chat/${receiverUsername}/?token=${accessToken}`;
      console.log(`Chat socket connecting: ${url}`);
  
      this.socket = new WebSocket(url);
  
      this.socket.onopen = () => {
        console.log('Chat WebSocket connected');
      };
  
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Chat WebSocket message received:', data);
        if (this.messageCallback) {
          this.messageCallback(data);
        }
      };
  
      this.socket.onclose = (event) => {
        console.log('Chat WebSocket closed:', event);
      };
  
      this.socket.onerror = (error) => {
        console.error('Chat WebSocket error:', error);
      };
    }
  
    disconnect() {
      // This will be called whenever user closes the chat
      if (this.socket) {
        console.log('Chat WebSocket disconnected');
        this.socket.close();
        this.socket = null;
        this.senderUsername = null;
      }
    }
  
    sendMessage(message, threadId) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const data = {
          message: message,
          thread_id: threadId,
          sender: this.senderUsername
        };
        console.log('Sending Chat message:', JSON.stringify(data));
        this.socket.send(JSON.stringify(data));
      } else {
        console.error('Chat WebSocket is not open. Cannot send message.');
      }
    }
  
    onMessage(callback) {
      this.messageCallback = callback;
    }
  }
  
  export default new ChatWebSocketService();
  