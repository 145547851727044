import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { axiosApiService } from '../../../utilities/api/axios';
import TinkerLabListAPIs from '../../../utilities/api/TinkersLab/TinkerLabListAPIs';
import dayjs from 'dayjs';

const POLLING_INTERVAL = 60000; // Poll every 1 minute

const TotalVisitorsToday = () => {
    const { user } = useSelector((state) => state.user.user); // Retrieve user from Redux store
    const [todayVisitors, setTodayVisitors] = useState(null);
    const [lastThreeDaysData, setLastThreeDaysData] = useState([]);
    const [highestCount, setHighestCount] = useState(null);
    const [averageCount, setAverageCount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchVisitorData = async () => {
        setError(null);

        try {
            const tinkerLabs = await TinkerLabListAPIs.TinkerLabsListGet();
            const tinkerLab = tinkerLabs.find((lab) => lab.name === user.TL_name);

            if (!tinkerLab) {
                throw new Error(`No TinkerLab found for TL_name: ${user.TL_name}`);
            }

            const today = dayjs();
            const promises = [];

            // Fetch visitor data for the last 7 days
            for (let i = 0; i < 7; i++) {
                const date = today.subtract(i, 'day').format('YYYY-MM-DD');
                promises.push(
                    axiosApiService.get(`/auth/unique-visitors/?TL=${tinkerLab.id}&date=${date}`).then((res) => ({
                        date: dayjs(date).format('DD/MM'),
                        unique_visitors: res.data.unique_visitors || 0,
                    }))
                );
            }

            const visitorData = await Promise.all(promises);

            // Separate data for display
            const todayData = visitorData[0];
            const lastThreeDays = visitorData.slice(0, 3);
            const highest = visitorData.reduce((max, day) => (day.unique_visitors > max.unique_visitors ? day : max));
            const average = (
                visitorData.reduce((sum, day) => sum + day.unique_visitors, 0) / visitorData.length
            ).toFixed(2);

            setTodayVisitors(todayData.unique_visitors);
            setLastThreeDaysData(lastThreeDays);
            setHighestCount(highest);
            setAverageCount(average);
        } catch (err) {
            setError(err.message || 'Failed to fetch visitor data.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Initial fetch
        fetchVisitorData();

        // Set up polling
        const intervalId = setInterval(fetchVisitorData, POLLING_INTERVAL);

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [user]);

    console.log('highestCount',highestCount)
    return (
        <Card
            sx={{
                borderRadius: '12px',
                backgroundColor: '#f9fafe',
                padding: '16px',
                width: '97%',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
            }}
        >
            <CardContent>
                <Typography
                    variant="h6"
                    sx={{ fontWeight: '700', marginBottom: '16px', textAlign: 'center' }}
                >
                    Visitor Statistics
                </Typography>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '120px',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography
                        variant="body1"
                        sx={{ color: 'red', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {error}
                    </Typography>
                ) : (
                    <Box>
                        {/* Today's Visitors */}
                        <Box sx={{ textAlign: 'center', marginBottom: '12px' }}>
                            <Typography variant="body1" sx={{ fontWeight: '700' }}>
                                Today's Visitors
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: '700',
                                    color: '#212121',
                                    marginTop: '4px',
                                }}
                            >
                                {todayVisitors}
                            </Typography>
                        </Box>

                        {/* Summary (Last 7 Days) */}
                        <Box>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: '600',
                                    textAlign: 'center',
                                    marginBottom: '8px',
                                }}
                            >
                                Summary (Last 7 Days)
                            </Typography>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    boxShadow: 'none',
                                    borderRadius: '12px',
                                    overflowX: 'auto',
                                }}
                            >
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>
                                                Metric
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>
                                                Value
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center' }}>Highest Count</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Tooltip title={`Date: ${highestCount.date}`} arrow>
                                                    <span>
                                                        {highestCount.unique_visitors} visitors on {highestCount.date}
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center' }}>Average Count</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {Math.round(averageCount)} {Math.round(averageCount) === 1 ? ' visitor' : ' visitors'}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default TotalVisitorsToday;
