import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg';

// Tier Mapping
const TIER_MAPPING = {
  1: 'Bookings',
  2: 'Messaging',
  3: 'Workshops',
  4: 'Records',
  5: 'Login',
  6: 'Issues/Purchases',
  7: 'Web Application',
  8: 'Other',
};

// Custom Hook for fetching issues data
const useIssuesData = (tlMappings) => {
  const [issueData, setIssueData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const issueCounts = {};
        const labels = Object.values(tlMappings);

        const fetchIssueData = async (TL_name) => {
          const response = await axiosApiService.get('/auth/report_user_issue_list/', {
            headers: { 'TL': TL_name },
          });
          return response.data;
        };

        await Promise.all(labels.map(async (tlName) => {
          const issues = await fetchIssueData(tlName);
          const issueCountsForTL = {};

          issues.forEach((issue) => {
            const tierLabel = TIER_MAPPING[issue.tier] || 'Other';
            issueCountsForTL[tierLabel] = (issueCountsForTL[tierLabel] || 0) + 1;
          });

          issueCounts[tlName] = issueCountsForTL;
        }));

        // Prepare data for each tier across all TLs
        const tiers = Object.values(TIER_MAPPING);
        const issueDataArray = tiers.map(tier => 
          labels.map(label => issueCounts[label]?.[tier] || 0)
        );

        setLabels(labels);
        setIssueData(issueDataArray);
      } catch (err) {
        console.error('Error fetching issue data:', err);
        setError('Failed to load issue data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tlMappings]);

  return { issueData, labels, isLoading, error };
};

// Reusable Chart Component for Multiple Datasets
const ChartComponent = ({ labels, data, datasetsLabels, colors, options }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!labels.length || !data.length || !datasetsLabels.length) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    const datasets = data.map((dataset, index) => ({
      label: datasetsLabels[index],
      data: dataset,
      backgroundColor: colors[index] || `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
    }));

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets,
      },
      options: options || {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: datasets.length > 0,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const label = tooltipItem.dataset.label || '';
                const value = tooltipItem.raw || 0;
                return `${label}: ${value}`;
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
            // max: Math.ceil(Math.max(...data.flat()) * 1.5) || 10,
            ticks: {
              stepSize: 1,
            },
            title: { display: true, text: 'Number of Issues' },
          },
        },
      },
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [labels, data, datasetsLabels, colors, options]);

  return <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

// Loading Indicator Component
const LoadingIndicator = ({ height }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height={height}
    width="100%"
  >
    <CircularProgress />
  </Box>
);

// Error Message Component
const ErrorMessage = ({ message, height }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={height}
    width="100%"
  >
    <Typography variant="h6" color="error">
      {message}
    </Typography>
  </Box>
);

// No Data Component
const NoData = ({ height }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={height}
    width="100%"
  >
    <img
      src={nodatafoundImage}
      alt="No Data Found"
      style={{ width: '200px', marginBottom: '16px' }}
    />
    <Typography variant="h5" color="textSecondary">
      No data available
    </Typography>
  </Box>
);

// Enlarged Chart Dialog Component
const EnlargedChartDialog = ({ labels, issueData, datasetsLabels, colors, open, handleClose }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth="lg"
    aria-labelledby="issues-chart-dialog-title"
    sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}
  >
    <DialogTitle id="issues-chart-dialog-title">
      Issues Breakdown - Detailed View
    </DialogTitle>
    <DialogContent>
      {issueData.length > 0 && Math.max(...issueData.flat()) > 0 ? (
        <Box
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ChartComponent
            labels={labels}
            data={issueData}
            datasetsLabels={datasetsLabels}
            colors={colors}
          />
        </Box>
      ) : (
        <NoData height="500px" />
      )}
    </DialogContent>
  </Dialog>
);

// Main IssuesChart Component
const IssuesChart = ({ tlMappings }) => {
  const { issueData, labels, isLoading, error } = useIssuesData(tlMappings);
  const [open, setOpen] = useState(false);

  // Handle dialog open/close
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Determine if there is any data to display
  const hasData = issueData.length > 0 && Math.max(...issueData.flat()) > 0;

  // Prepare datasets labels and colors
  const datasetsLabels = Object.values(TIER_MAPPING);
  const predefinedColors = [
    'rgba(75, 192, 192, 0.6)', // Bookings
    'rgba(255, 99, 132, 0.6)', // Messaging
    'rgba(255, 206, 86, 0.6)', // Workshops
    'rgba(54, 162, 235, 0.6)', // Records
    'rgba(153, 102, 255, 0.6)', // Login
    'rgba(255, 159, 64, 0.6)', // Issues/Purchases
    'rgba(192, 140, 140, 0.6)', // Web Application
    'rgba(201, 203, 207, 0.6)', // Other
  ];

  return (
    <>
      <Card
        sx={{
          borderRadius: '24px',
          backgroundColor: '#f9fafe',
          borderColor: '#d3d3d3',
          borderWidth: 1,
          borderStyle: 'solid',
          width: '90%',
          height: 336,
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <CardContent sx={{ width: '100%' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            mt={-3}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#212121',
                textAlign: 'center',
                letterSpacing: 0,
              }}
            >
              Types of Issues
            </Typography>
            <IconButton onClick={handleOpen} aria-label="Expand chart">
              <ExpandMoreIcon sx={{rotate:"-135deg",}} />
            </IconButton>
          </Box>

          {isLoading ? (
            <LoadingIndicator height="250px" />
          ) : error ? (
            <ErrorMessage message={error} height="250px" />
          ) : hasData ? (
            <Box
              sx={{
                height: '300px',
                width: '100%',
                marginTop: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ChartComponent
                labels={labels}
                data={issueData}
                datasetsLabels={datasetsLabels}
                colors={predefinedColors}
              />
            </Box>
          ) : (
            <NoData height="250px" />
          )}
        </CardContent>
      </Card>

      {/* Enlarged Chart Dialog */}
      <EnlargedChartDialog
        labels={labels}
        issueData={issueData}
        datasetsLabels={datasetsLabels}
        colors={predefinedColors}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default IssuesChart;
