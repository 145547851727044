// src/components/IndividualChat.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Paper, TextField, IconButton, Avatar } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
import ChatWebSocketService from '../../utilities/api/IMS/ChatWebSocketService';
import { useSelector, useDispatch } from 'react-redux';
import {
  addMessage,
  clearUnreadCount,
  setActiveChatUser,
  setCurrentThreadId
} from '../../redux/slices/messagesSlice';
import { v4 as uuidv4 } from 'uuid';

const IndividualChat = ({
  threadId,
  userName,
  initialMessages,
  onBack,
  addChatToChatList
}) => {
  const dispatch = useDispatch();
  const { access, user } = useSelector(state => state.user.user);

  // Local state for messages
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentThreadId, setCurrentThreadIdLocal] = useState(threadId);
  const [pendingMessage, setPendingMessage] = useState(null);
  const [lastSentMessageId, setLastSentMessageId] = useState(null);

  const chatContainerRef = useRef(null);

  /** 
   * Filter only messages between me (user.username) and userName 
   */
  const filterMessages = (msgs) => {
    return msgs.filter(
      message => message.sender === user.username || message.sender === userName
    );
  };

  /**
   * Fetch the latest messages for this thread (via REST)
   */
  const fetchLatestMessages = async () => {
    if (currentThreadId) {
      try {
        const threadDetail = await AdminMessagingAPIs.GetThreadDetail(currentThreadId);
        const filtered = filterMessages(threadDetail.messages);
        const formatted = filtered.map(m => ({
          id: m.id,
          text: m.content,
          date: m.timestamp,
          sender: m.sender,
          threadId: m.thread
        }));
        setMessages(formatted);

        // Mark them as read in Redux 
        dispatch(clearUnreadCount(currentThreadId));
      } catch (err) {
        console.error(`Error fetching latest messages for thread ${currentThreadId}:`, err);
      }
    }
  };

  // Fetch messages whenever currentThreadId changes
  useEffect(() => {
    fetchLatestMessages();
  }, [currentThreadId]);

  // Initialize from the props
  useEffect(() => {
    if (initialMessages && initialMessages.length > 0) {
      setMessages(filterMessages(initialMessages));
    }
  }, [initialMessages]);

  // Auto-scroll to the bottom when messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  /**
   * As soon as we have a threadId, set in Redux so 
   * we don’t count new messages as unread for this thread
   */
  useEffect(() => {
    if (currentThreadId !== null) {
      dispatch(setCurrentThreadId(currentThreadId));
      dispatch(setActiveChatUser(userName));
      // Clear unread for this thread as soon as we open it
      dispatch(clearUnreadCount(currentThreadId));
    }
  }, [currentThreadId, userName, dispatch]);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  /**
   * Sending messages
   */
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const messageId = uuidv4();
      setLastSentMessageId(messageId);

      const newMsg = {
        id: messageId,
        text: newMessage,
        date: new Date().toLocaleString(),
        sender: user.username
      };
      setNewMessage("");

      if (currentThreadId === null) {
        // 1) No thread => create one, then send message
        setPendingMessage(newMsg);

        try {
          const newThread = await AdminMessagingAPIs.CreateThread({
            participants: [userName],
            messages: []
          });
          setCurrentThreadIdLocal(newThread.id);
          dispatch(setCurrentThreadId(newThread.id));
          dispatch(setActiveChatUser(userName));

          console.log("New thread created:", newThread.id);

          // 2) Send the first message via REST
          await AdminMessagingAPIs.PostMessage(newThread.id, {
            content: newMsg.text
          });

          // 3) Update local UI
          setMessages(prev => [...prev, newMsg]);

          // 4) Also add the new thread to the chat list
          addChatToChatList({
            id: newThread.id,
            userName: userName,
            message: newMsg.text,
            date: newMsg.date,
            sender: user.username
          });

          // 5) Connect Chat WebSocket for subsequent messages
          ChatWebSocketService.connect(access, userName, user.username);
        } catch (error) {
          console.error("Error creating new thread or sending first message:", error);
          setPendingMessage(null);
        }
      } else {
        // If a thread already exists, send via WebSocket
        setMessages(prev => [...prev, newMsg]);
        ChatWebSocketService.sendMessage(newMsg.text, currentThreadId);
      }
    }
  };

  /**
   * Handle incoming WebSocket messages for this specific chat
   */
  const handleIncomingMessage = useCallback((data) => {
    console.log("Chat incoming message:", data);

    // Only accept messages from this user or from me
    if (data.sender !== userName && data.sender !== user.username) {
      return;
    }
    // Avoid duplicates if same ID
    if (data.id && data.id === lastSentMessageId) {
      return;
    }

    const incoming = {
      id: data.id || uuidv4(),
      threadId: data.thread_id || currentThreadId,
      text: data.message,
      date: data.timestamp
        ? new Date(data.timestamp).toLocaleString()
        : new Date().toLocaleString(),
      sender: data.sender,
      // Because we're in this thread, it should be read
      read: true,
    };

    // Add to Redux for consistent state
    dispatch(addMessage(incoming));

    // Also add to local state so it shows in this component
    setMessages(prev => [...prev, incoming]);
  }, [lastSentMessageId, userName, user.username, currentThreadId, dispatch]);

  /**
   * Open the chat socket if threadId is known, close on unmount
   */
  useEffect(() => {
    if (currentThreadId !== null) {
      ChatWebSocketService.connect(access, userName, user.username);
      ChatWebSocketService.onMessage(handleIncomingMessage);
    }
    return () => {
      // Cleanup => close chat socket
    //   ChatWebSocketService.disconnect();
    //   dispatch(setActiveChatUser(null));
    //   dispatch(setCurrentThreadId(null));
    };
  }, [access, userName, handleIncomingMessage, currentThreadId, dispatch]);

  /**
   * If we created a thread late, 
   * send the pending message via ChatWebSocket
   */
  useEffect(() => {
    if (pendingMessage && currentThreadId) {
      ChatWebSocketService.sendMessage(pendingMessage.text, currentThreadId);
      setPendingMessage(null);
    }
  }, [pendingMessage, currentThreadId]);

  const handleBack = () => {
    onBack();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          backgroundColor: '#f5f5f5',
          borderBottom: '1px solid #ddd'
        }}
      >
        <IconButton onClick={handleBack}>
          <ArrowBack />
        </IconButton>
        <Avatar sx={{ marginLeft: '10px' }} />
        <Typography variant="h6" sx={{ marginLeft: '10px' }}>
          {userName}
        </Typography>
      </Box>

      {/* Messages */}
      <Box
        ref={chatContainerRef}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '10px',
          backgroundColor: '#e5ddd5'
        }}
      >
        {messages.map((msg) => (
          <Box
            key={msg.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: msg.sender === user.username ? 'flex-end' : 'flex-start',
              marginBottom: '10px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: msg.sender === user.username ? '#dcf8c6' : '#fff',
                borderRadius: '10px',
                padding: '10px',
                maxWidth: '60%',
                boxShadow: '0px 1px 2px rgba(0,0,0,0.1)'
              }}
            >
              {msg.sender !== user.username && (
                <Avatar sx={{ width: 30, height: 30, marginRight: '10px' }} />
              )}
              <Typography variant="body1">{msg.text}</Typography>
            </Box>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ marginTop: '2px' }}
            >
              {msg.date}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Input */}
      <Paper
        component="form"
        onSubmit={handleSendMessage}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 10px',
          boxShadow: 'none',
          borderTop: '1px solid #ddd',
          position: 'sticky',
          bottom: 0,
          backgroundColor: '#fff'
        }}
      >
        <TextField
          sx={{ flex: 1 }}
          placeholder="Type a message"
          value={newMessage}
          onChange={handleInputChange}
          variant="outlined"
        />
        <IconButton type="submit">
          <SendIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default IndividualChat;
