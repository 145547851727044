// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppRoutes } from './routes';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from './redux/slices/messagesSlice'; // <-- import addMessage
// import ChatBar from './components/Messaging/Chatbar';
import Reportbutton from './components/reportButton/reportButton';
import 'react-toastify/dist/ReactToastify.css';

// Global WebSocket (or rename it GlobalGlobalWebSocketService)
import GlobalWebSocketService from './utilities/api/IMS/GlobalWebSocketService';
// import GlobalWebSocketService from './utilities/api/IMS/GlobalWebSocketService';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';

// Push notification utilities
import {
  askNotificationPermission,
  subscribeUserToPush,
  sendSubscriptionToBackend
} from './pushNotifications';

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user); // Adjusted for your store shape

  const [notifications, setNotifications] = useState([]); // local array if you need

  // const logRocketAppId = process.env.REACT_APP_LOG_ROCKET_APP_ID;

  // console.log(logRocketAppId);

  // useEffect(() => {
  //   LogRocket.init(logRocketAppId);
  //   setupLogRocketReact(LogRocket);

  //   if (user) {
  //     LogRocket.identify(user.id, {
  //       name: user.username,
  //       email: user.email,
  //       is_staff: user.is_staff,
  //       is_student: user.is_student,
  //       is_active: user.is_active,
  //     });
  //   }
  // },[user])

  // 1) Setup Push Notifications (unchanged from your code)
  useEffect(() => {
    if (!user) return;

    async function setupPushNotifications() {
      console.log('Setting up push notifications... (App.js useEffect)');
      try {
        if ('serviceWorker' in navigator) {
          const existingRegistration = await navigator.serviceWorker.getRegistration();
          let registration = existingRegistration;

          if (!existingRegistration) {
            console.log('Registering new Service Worker...');
            registration = await navigator.serviceWorker.register('/service-worker.js');
            console.log('Service Worker registered with scope:', registration.scope);
          }

          // Check/ask for notification permission
          const permission = await askNotificationPermission();
          if (permission !== 'granted') {
            console.error('Notification permission denied.');
            return;
          }

          // Check existing subscription
          const existingSubscription = await registration.pushManager.getSubscription();
          if (existingSubscription) {
            console.log('Already subscribed:', existingSubscription);
            await sendSubscriptionToBackend(existingSubscription, user.user);
          } else {
            // Subscribe the user if not subscribed
            const newSubscription = await subscribeUserToPush();
            console.log('New push subscription:', newSubscription);
            await sendSubscriptionToBackend(newSubscription, user.user);
          }
        } else {
          console.error('Service Worker not supported in this browser.');
        }
      } catch (error) {
        console.error('Error setting up push notifications:', error);
      }
    }

    setupPushNotifications();
  }, [user]);

  // 2) Global WebSocket connection
  useEffect(() => {
    if (!user.user) return;

    // Optional: request notification permission
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }

    console.log(user.access, user.user.username, user.user.username)

    // Connect Global WebSocket
    GlobalWebSocketService.connect(user.access, user.user.username, user.user.username);

    // Listen for incoming messages
    GlobalWebSocketService.onMessage((messageData) => {
      console.log("Global WebSocket message received in App.js:", messageData);

      if (messageData && messageData.message && messageData.sender) {
        // Construct a Redux-friendly "message" object
        console.log('Incoming message via GlobalWebSocket:', messageData);

        const incomingMessage = {
          id: messageData.id || Date.now(),
          threadId: messageData.thread_id,
          text: messageData.message,
          date: messageData.timestamp
            ? new Date(messageData.timestamp).toLocaleString()
            : new Date().toLocaleString(),
          sender: messageData.sender,
          read: false, // by default, messages are unread
        };

        // Dispatch to Redux => triggers unreadCount updates
        dispatch(addMessage(incomingMessage));

        // If you also maintain a local notifications[]:
        const newNotification = {
          avatar: "https://www.example.com/avatar.jpg",
          message: `Message from ${messageData.sender}: ${messageData.message}`,
          time: new Date().toLocaleTimeString() + " • Today",
        };
        setNotifications((prev) => [...prev, newNotification]);

        // Show a browser notification if desired
        if (Notification.permission === 'granted') {
          new Notification('New Message', {
            body: newNotification.message,
            icon: newNotification.avatar,
          });
        }
      }
    });

    // Cleanup if you want to disconnect on unmount/log-out:
    return () => {
      // If your requirement is to keep it open until logout, remove or handle in logout.
      // GlobalWebSocketService.disconnect();
    };
  }, [user, dispatch]);

  return (
    <BrowserRouter>
      <AppRoutes />
      <Reportbutton />

      {/* Global ChatBar – no changes needed here */}
      {/* <ChatBar /> */}

      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
