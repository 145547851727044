// WebSocketService.js
import {store} from '../../../redux/store'; // Adjust the path as necessary
import { addMessage } from '../../../redux/slices/messagesSlice';
import { v4 as uuidv4 } from 'uuid';

class WebSocketService {
  constructor() {
    this.socket = null;
    this.messageCallback = null;
    this.senderUsername = null; // Track the sender's username
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectDelay = 1000; // Start with 1 second
  }

  connect(accessToken, receiverUsername, senderUsername) {
    if (this.socket) {
      this.socket.close();
    }

    this.senderUsername = senderUsername; // Set the sender's username

    const url = `wss://sys.staging.mis.mapit.ai/ws/chat/${receiverUsername}/?token=${accessToken}`;
    console.log(`Connecting to WebSocket at URL: ${url}`);
    console.log(`Connecting with USER Name: ${receiverUsername}`);

    this.socket = new WebSocket(url);

    this.socket.onopen = () => {
      console.log('WebSocket connected');
      this.reconnectAttempts = 0; // Reset on successful connection
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket message received:', data);

      // Create a message object
      const incomingMessage = {
        id: data.id || uuidv4(),
        threadId: data.thread_id, // Ensure threadId is present
        text: data.message,
        date: data.timestamp ? new Date(data.timestamp).toLocaleString() : new Date().toLocaleString(),
        sender: data.sender,
        read: false, // Initially, the message is unread
      };

      // Dispatch the message to Redux
      store.dispatch(addMessage(incomingMessage));

      // Call the callback if set (existing functionality)
      if (this.messageCallback) {
        this.messageCallback(data);
      }
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket closed:', event);
      console.log('WebSocket closed srcURL:', event.srcElement.url);
      if (event.reason) {
        console.error('Reason for closure:', event.reason);
      }

      // Attempt to reconnect with exponential backoff
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => {
          this.reconnectAttempts += 1;
          this.reconnectDelay *= 2; // Exponential backoff
          console.log(`Reconnecting attempt ${this.reconnectAttempts}`);
          this.connect(accessToken, receiverUsername, senderUsername);
        }, this.reconnectDelay);
      } else {
        console.error('Max reconnection attempts reached.');
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  disconnect() {
    if (this.socket) {
      console.log('WebSocket disconnected', this.socket);
      this.socket.close();
      this.socket = null;
      this.senderUsername = null;
    }
  }

  sendMessage(message, threadId) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const data = {
        message: message,
        thread_id: threadId,
        sender: this.senderUsername, // Now properly set
      };
      console.log('Sending WebSocket message:', JSON.stringify(data));
      this.socket.send(JSON.stringify(data));

      // Do not trigger the callback here to avoid duplication in the receiver's UI
    } else {
      console.error('WebSocket is not open. Cannot send message.');
    }
  }

  onMessage(callback) {
    this.messageCallback = callback;
  }
}

export default new WebSocketService();
