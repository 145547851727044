import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const StatisticCard = ({ value, label, description, bgColor, borderColor }) => (
  <Card sx={{ width:'100%' ,backgroundColor: bgColor, marginBottom: '10px', flex: '1 1 auto', borderColor: borderColor, borderWidth: 1, borderStyle: 'solid', borderRadius: '12px', height: '110px' }}>
    <CardContent sx={{ padding: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {value > 0 ? (
        <>
          <Typography variant="h5" sx={{ fontSize: '25px', fontWeight: '700', color: '#212121' }}>{value}</Typography>
          <Typography variant="body2" sx={{ fontSize: '14px', textTransform: 'capitalize', color: '#212121', marginTop: '4px', fontWeight: '500' }}>{label}</Typography>
          <Typography variant="caption" sx={{ fontSize: '12px', textTransform: 'lowercase', color: '#7a7a7a', marginTop: '4px' }}>{description}</Typography>
        </>
      ) : (
        <>
          <img src={nodatafoundImage} alt="No Data Found" style={{ width: '60px', marginRight: '16px' }} />
          <Box>
            <Typography variant="body2" sx={{ fontSize: '14px', textTransform: 'capitalize', color: '#212121', fontWeight: '500', textAlign: 'center' }}>{label}</Typography>
            <Typography variant="h6" sx={{ fontSize: '12px', color: '#7a7a7a', marginTop: '4px', textAlign: 'center' }}>No data available</Typography>
          </Box>
        </>
      )}
    </CardContent>
  </Card>
);

const GeneralStatsCard = ({ tlMappings }) => {
  const [workshopCount, setWorkshopCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [issueCount, setIssueCount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        
        // Explicitly adding "Org-Admin" to the list of TLs for ISSUE Count
        const combinedMappings = { ...tlMappings, "Org-Admin": "Org-Admin" };
        
        const tlData = await Promise.all(
          Object.entries(combinedMappings).map(async ([tlId, tlName]) => {
            const [workshopResponse, projectResponse, issueResponse] = await Promise.all([
              axiosApiService.get('/training/workshops/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/project/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/auth/report_user_issue_list/', {
                headers: {
                  'TL': tlName,
                },
              })
            ]);

            return {
              workshopCount: workshopResponse.data.length,
              projectCount: projectResponse.data.length,
              issueCount: issueResponse.data.length
            };
          })
        );

        const totalWorkshopCount = tlData.reduce((acc, curr) => acc + curr.workshopCount, 0);
        const totalProjectCount = tlData.reduce((acc, curr) => acc + curr.projectCount, 0);
        const totalIssueCount = tlData.reduce((acc, curr) => acc + curr.issueCount, 0);

        setWorkshopCount(totalWorkshopCount);
        setProjectCount(totalProjectCount);
        setIssueCount(totalIssueCount);
      } catch (error) {
        console.error('Error fetching stats data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  return (
    <Card sx={{ height: '385px', boxShadow: 'none' }}>
      <CardContent>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <StatisticCard value={workshopCount} label="Workshops" description="Total number of workshops" bgColor="#fef1ff" borderColor="#e339ff" />
          <StatisticCard value={projectCount} label="Projects Covered" description="Total number of projects" bgColor="#e7f3ef" borderColor="#42d3b6" />
          <StatisticCard value={issueCount} label="Issues Reported" description="Total number of issues reported" bgColor="#fffae6" borderColor="#ffc639" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default GeneralStatsCard;
