// ChatCard.js
import React from 'react';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const ChatCard = ({ userName, message, date, sender, onClick }) => {
    const { user } = useSelector(state => state.user.user);
    const formattedDate = new Date(date).toLocaleString("en-GB", {
        dateStyle: "short",
        timeStyle: "short",
    });

    const isSenderYou = sender === user.username;

    return (
        <Box
            onClick={onClick}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => { if (e.key === 'Enter') onClick(); }}
            sx={{
                alignItems: 'center',
                display: 'flex',
                padding: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '5px',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                maxWidth: '100%',
                height: '50px',
                overflow: 'hidden',
                cursor: 'pointer',
                marginBottom: '5px',
            }}
        >
            <Avatar sx={{ marginRight: '10px' }} />
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {userName}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {isSenderYou ? 'You: ' : ''}{message}
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'right', alignSelf: 'flex-start' }}>
                <Typography variant="caption" color="textSecondary">
                    {formattedDate}
                </Typography>
                <IconButton size="small" sx={{ marginLeft: '5px' }}>
                    <MoreVert fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
};

export default React.memo(ChatCard);
