import React, { useEffect, useState, useRef } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { axiosApiService } from '../../../utilities/api/axios';
import TinkerLabListAPIs from '../../../utilities/api/TinkersLab/TinkerLabListAPIs';

const POLLING_INTERVAL = 10000; // Poll every 30 seconds

const CameraStatsChart = () => {
    const { user } = useSelector((state) => state.user.user);
    const chartRef = useRef(null);
    const cameraChartInstance = useRef(null);
    const [cameraStats, setCameraStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCameraStats = async () => {
        setError(null);
        try {
            const tinkerLabs = await TinkerLabListAPIs.TinkerLabsListGet();
            const tinkerLab = tinkerLabs.find((lab) => lab.name === user.TL_name);

            if (!tinkerLab) {
                throw new Error(`No TinkerLab found for TL_name: ${user.TL_name}`);
            }

            const response = await axiosApiService.get(`/auth/camerastats_chart/?TL=${tinkerLab.id}`);
            setCameraStats(response.data || []);
        } catch (err) {
            setError(err.message || 'Failed to fetch camera stats.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Initial fetch
        fetchCameraStats();

        // Set up polling
        const intervalId = setInterval(fetchCameraStats, POLLING_INTERVAL);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [user]);

    const groupDataBy10Minutes = (stats) => {
        const groupedData = {};

        stats.forEach((stat) => {
            const timestamp = new Date(stat.timestamp);
            const minutes = timestamp.getMinutes();
            const roundedMinutes = Math.floor(minutes / 10) * 10;
            const interval = `${timestamp.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })} ${timestamp
                .getHours()
                .toString()
                .padStart(2, '0')}:${roundedMinutes.toString().padStart(2, '0')}`;

            if (!groupedData[interval]) {
                groupedData[interval] = 0;
            }
            groupedData[interval] += stat.count;
        });

        return groupedData;
    };

    useEffect(() => {
        if (!chartRef.current || cameraStats.length === 0) return;

        const groupedStats = groupDataBy10Minutes(cameraStats);

        const labels = Object.keys(groupedStats).sort();
        const data = labels.map((label) => groupedStats[label]);

        const renderChart = (context) => {
            if (cameraChartInstance.current) {
                cameraChartInstance.current.destroy();
            }

            const latestLabelIndex = labels.length - 1;

            cameraChartInstance.current = new Chart(context, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Camera Stats Count (Grouped by 10 minutes)',
                            data: data,
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            fill: true,
                            tension: 0.4,
                            pointBackgroundColor: labels.map((_, index) =>
                                index === latestLabelIndex ? 'red' : 'rgba(75, 192, 192, 1)'
                            ),
                            pointRadius: labels.map((_, index) => (index === latestLabelIndex ? 6 : 3)),
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    return context.dataIndex === latestLabelIndex
                                        ? `${context.raw} (Live)`
                                        : context.raw;
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Time',
                            },
                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Count',
                            },
                        },
                    },
                },
            });
        };

        renderChart(chartRef.current.getContext('2d'));

        return () => {
            if (cameraChartInstance.current) cameraChartInstance.current.destroy();
        };
    }, [cameraStats]);

    return (
        <Card
            sx={{
                borderRadius: '16px',
                backgroundColor: '#f9fafe',
                padding: 0.5,
                width: '101%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 3,
            }}
        >
            <CardContent sx={{ width: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: '700', marginBottom: '16px' }}>
                    Camera Stats Line Chart (Grouped by 10 Minutes)
                </Typography>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography variant="body2" sx={{ color: 'red', textAlign: 'center' }}>
                        {error}
                    </Typography>
                ) : cameraStats.length === 0 ? (
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#7a7a7a' }}>
                        No data available to display.
                    </Typography>
                ) : (
                    <Box
                        sx={{
                            position: 'relative',
                            height: '250px',
                        }}
                    >
                        <canvas ref={chartRef} />
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default CameraStatsChart;
