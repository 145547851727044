import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const IssuesResolvedChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const issueChartRef = useRef(null);
  const [resolvedData, setResolvedData] = useState({ resolved: 0, unresolved: 0 });
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const combinedMappings = { ...tlMappings, "Org-Admin": "Org-Admin" };

        const resolvedCounts = { resolved: 0, unresolved: 0 };

        const fetchIssueData = async (TL) => {
          const response = await axiosApiService.get('/auth/report_user_issue_list/', {
            headers: {
              'TL': TL,
            },
          });
          return response.data;
        };

        for (const TL of Object.values(combinedMappings)) {
          const issues = await fetchIssueData(TL);
          issues.forEach((issue) => {
            if (issue.resolved) {
              resolvedCounts.resolved++;
            } else {
              resolvedCounts.unresolved++;
            }
          });
        }

        setResolvedData(resolvedCounts);
      } catch (error) {
        console.error('Error fetching issue data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  const renderChart = (context) => {
    return new Chart(context, {
      type: 'doughnut',
      data: {
        labels: ['Resolved', 'Yet to be resolved'],
        datasets: [
          {
            data: [resolvedData.resolved, resolvedData.unresolved],
            backgroundColor: ['#66bb6a', '#ef5350'],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  useEffect(() => {
    if (issueChartRef.current) issueChartRef.current.destroy();
    if (chartRef.current) issueChartRef.current = renderChart(chartRef.current.getContext('2d'));

    return () => {
      if (issueChartRef.current) issueChartRef.current.destroy();
    };
  }, [resolvedData]);

  return (
    <>
      <Card
        sx={{
          borderRadius: '24px',
          backgroundColor: '#f9fafe',
          borderColor: '#dfacff',
          borderWidth: 1,
          borderStyle: 'solid',
          flex: 1,
          width: '85%',
          height: 330,
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CardContent sx={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={-3}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '25px',
                fontWeight: '700',
                color: '#212121',
                textAlign: 'left',
                letterSpacing: 0,
              }}
            >
              Issues Resolved
            </Typography>
            <IconButton onClick={handleOpen} aria-label="Expand chart">
              <ExpandMoreIcon sx={{rotate:"-135deg"}} />
            </IconButton>
          </Box>
          {resolvedData.resolved > 0 || resolvedData.unresolved > 0 ? (
            <Box
              sx={{
                height: '250px',
                width: '100%',
                marginTop: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <canvas ref={chartRef} style={{ width: '100%' }} />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
              width="100%"
            >
              <img
                src={nodatafoundImage}
                alt="No Data Found"
                style={{ width: '200px', marginBottom: '16px' }}
              />
              <Typography variant="h5" color="textSecondary">
                No data available
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="expanded-chart-dialog-title"
        sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}
      >
        <DialogTitle id="expanded-chart-dialog-title">Expanded Issues Resolved Chart</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: '500px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <canvas
              ref={(dialogCanvas) => {
                if (dialogCanvas && open) {
                  renderChart(dialogCanvas.getContext('2d'));
                }
              }}
              style={{ width: '100%' }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default IssuesResolvedChart;
