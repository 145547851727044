import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosApiService } from '../../../utilities/api/axios';
import dayjs from 'dayjs';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const BookingHoursChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const bookingChartRef = useRef(null);
  const [bookingData, setBookingData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const bookingUsage = {};

        const fetchBookingData = async (TL_name) => {
          const response = await axiosApiService.get('/machine_reservation_list/', {
            headers: {
              'TL': TL_name,
            },
          });
          return response.data;
        };

        const isWithinLastTwoMonths = (dateString) => {
          const reservationDate = dayjs(dateString);
          const today = dayjs();
          const twoMonthsAgo = today.subtract(3, 'month');
          return reservationDate.isAfter(twoMonthsAgo) && reservationDate.isBefore(today);
        };

        for (const name of Object.values(tlMappings)) {
          const data = await fetchBookingData(name);
          data.forEach(item => {
            if (
              (item.approved === "Approved" || item.approved === "AutoApproved") &&
              isWithinLastTwoMonths(item.reserved_date)
            ) {
              const month = dayjs(item.reserved_date).format('MMMM');
              if (!bookingUsage[name]) {
                bookingUsage[name] = {};
              }
              if (!bookingUsage[name][month]) {
                bookingUsage[name][month] = 0;
              }
              bookingUsage[name][month] += item.duration;
            }
          });
        }

        const allMonths = Array.from(new Set(Object.values(bookingUsage)
          .flatMap(Object.keys)))
          .sort((a, b) => dayjs(a, 'MMMM').month() - dayjs(b, 'MMMM').month());

        const datasets = Object.entries(bookingUsage).map(([label, data]) => ({
          label,
          data: allMonths.map(month => data[month] || 0),
          borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
          backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`,
          fill: true,
        }));

        setLabels(allMonths);
        setBookingData(datasets);
      } catch (error) {
        console.error('Error fetching booking hours data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  const renderChart = (context) => {
    const extendedMaxY = Math.max(...bookingData.flatMap(dataset => dataset.data)) * 1.5;

    return new Chart(context, {
      type: 'line',
      data: {
        labels: labels,
        datasets: bookingData,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
            max: extendedMaxY,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (bookingChartRef.current) bookingChartRef.current.destroy();
    if (chartRef.current) bookingChartRef.current = renderChart(chartRef.current.getContext('2d'));

    return () => {
      if (bookingChartRef.current) bookingChartRef.current.destroy();
    };
  }, [labels, bookingData]);

  return (
    <>
      <Card
        sx={{
          borderRadius: '24px',
          backgroundColor: '#f9fafe',
          borderColor: '#d3d3d3',
          borderWidth: '1px',
          borderStyle: 'solid',
          padding: '16px',
          width: '90%',
          height: '336px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={-3}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#212121',
                textAlign: 'left',
                letterSpacing: 0,
              }}
            >
              Booking Hours
            </Typography>
            <IconButton onClick={handleOpen} aria-label="Expand chart">
              <ExpandMoreIcon sx={{rotate:"-135deg",}} />
            </IconButton>
          </Box>
          <Box sx={{ marginTop: '8px', alignSelf: 'stretch' }}>
            {bookingData.length > 0 && bookingData.some(dataset => dataset.data.some(value => value > 0)) ? (
              <Box sx={{ height: '300px', width: '100%' }}>
                <canvas ref={chartRef} style={{ width: '100%' }} />
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="250px"
                width="100%"
              >
                <img
                  src={nodatafoundImage}
                  alt="No Data Found"
                  style={{ width: '200px', marginBottom: '16px' }}
                />
                <Typography variant="h5" color="textSecondary">
                  No data available
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="expanded-chart-dialog-title"
        sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}
      >
        <DialogTitle id="expanded-chart-dialog-title">Expanded Booking Hours Chart</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: '500px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <canvas
              ref={(dialogCanvas) => {
                if (dialogCanvas && open) {
                  renderChart(dialogCanvas.getContext('2d'));
                }
              }}
              style={{ width: '100%' }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BookingHoursChart;
