// src/components/ChatBar.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, IconButton, Paper, Typography, TextField, Checkbox } from '@mui/material';
import { ExpandLess, ExpandMore, OpenInFull } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import ChatCard from './ChatCard';
import IndividualChat from './IndividualChat';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
import AdminGetUserAPIs from '../../utilities/api/workshop/AdminGetUser';
import debounce from 'lodash.debounce';

// Import additional actions as needed
import {
  setActiveChatUser,
  setCurrentThreadId,
  clearUnreadCount, // <--- We'll use this to mark messages read
} from '../../redux/slices/messagesSlice';
import ChatWebSocketService from '../../utilities/api/IMS/ChatWebSocketService';

const ChatBar = () => {
  const { user } = useSelector(state => state.user.user);
  const messages = useSelector(state => state.messages.messages);
  const activeChatUser = useSelector(state => state.messages.activeChatUser);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [rightOffset, setRightOffset] = useState(20);

  const navigate = useNavigate();
  const location = useLocation();
  const chatBarRef = useRef(null);
  const isDragging = useRef(false);
  const dragStarted = useRef(false);
  const prevIsOpenRef = useRef(isOpen);

  const dispatch = useDispatch();

  // Fetch threads whenever the ChatBar is opened or when messages change
  useEffect(() => {
    if ((isOpen && !prevIsOpenRef.current) || messages) {
      fetchThreadList();
    }
    prevIsOpenRef.current = isOpen;
  }, [isOpen, messages]);

  const fetchThreadList = async () => {
    try {
      const threads = await AdminMessagingAPIs.GetThreadList();
      console.log('threads', threads);

      const filteredThreads = threads.filter(thread =>
        thread.messages.some(message =>
          message.sender === user.username || message.receivers.includes(user.username)
        )
      );

      setChatList(filteredThreads.map(thread => {
        const otherUser = thread.participants.find(
          participant => participant !== user.username
        );
        const latestMessage = thread.latest_message;
        return {
          id: thread.id,
          userName: otherUser || '',
          message: latestMessage ? latestMessage.content : '',
          date: latestMessage ? latestMessage.timestamp : '',
          sender: latestMessage ? latestMessage.sender : '',
        };
      }));
    } catch (error) {
      console.error("Error fetching thread list:", error);
    }
  };

  const fetchThreadDetail = async (id) => {
    try {
      const threadDetail = await AdminMessagingAPIs.GetThreadDetail(id);
      return {
        ...threadDetail,
        messages: threadDetail.messages.map(message => ({
          id: message.id,
          text: message.content,
          date: message.timestamp,
          sender: message.sender,
        })),
        otherUser:
          threadDetail.messages[0].sender === user.username
            ? threadDetail.messages[0].receivers.find(
                receiver => receiver !== user.username
              )
            : threadDetail.messages[0].sender
      };
    } catch (error) {
      console.error(`Error fetching thread detail for ID ${id}:`, error);
      return null;
    }
  };

  const toggleChatBar = () => {
    if (!dragStarted.current) {
      setIsOpen(!isOpen);
    }
    dragStarted.current = false;
    if(activeChatUser){
      // Cleanup => close chat socket
      ChatWebSocketService.disconnect();
      dispatch(setActiveChatUser(null));
      dispatch(setCurrentThreadId(null));
    }
  };

  const debouncedHandleInputChange = useRef(
    debounce(async (event, newInputValue) => {
      if (newInputValue.length > 0) {
        try {
          const users = await AdminGetUserAPIs.UserDetailsGet(newInputValue);
          const filteredUsers = users.filter((value) => value.TL_name === user.TL_name);
          setUserSuggestions(filteredUsers.map(u => ({
            id: u.id,
            name: u.name,
            email: u.email,
            username: u.username
          })));
        } catch (error) {
          console.error("Error fetching user suggestions:", error);
        }
      } else {
        setUserSuggestions([]);
      }
    }, 300)
  ).current;

  const handleInputChange = (event, newInputValue) => {
    debouncedHandleInputChange(event, newInputValue);
  };

  useEffect(() => {
    return () => {
      debouncedHandleInputChange.cancel();
    };
  }, [debouncedHandleInputChange]);

  const handleUserSelect = (event, value) => {
    if (value) {
      const newChat = {
        id: null,
        otherUser: value.username,
        messages: []
      };
      setSelectedChat(newChat);
    }
  };

  const handleFullScreenClick = (e) => {
    e.stopPropagation();
    navigate('/messages', { state: { from: location.pathname } });
  };

  /**
   * When the user clicks a ChatCard:
   * 1) Fetch the thread details
   * 2) Set selectedChat
   * 3) Dispatch setActiveChatUser and setCurrentThreadId
   * 4) Optionally clear unread for that thread
   */
  const handleChatCardClick = async (chat) => {
    const threadDetail = await fetchThreadDetail(chat.id);
    if (threadDetail) {
      setSelectedChat({
        ...chat,
        ...threadDetail,
      });
      // Mark this user as active, and set the current thread
      dispatch(setActiveChatUser(threadDetail.otherUser));
      dispatch(setCurrentThreadId(threadDetail.id));

      // Immediately clear unread for this thread so the badge updates
      dispatch(clearUnreadCount(threadDetail.id));
    }
  };

  const handleBack = () => {
    setSelectedChat(null);
    ChatWebSocketService.disconnect();
    // Clear active user and current thread in Redux
    dispatch(setActiveChatUser(null));
    dispatch(setCurrentThreadId(null));
    fetchThreadList();
  };

  const startDrag = (e) => {
    if (!isOpen) {
      e.preventDefault();
      isDragging.current = true;
      dragStarted.current = false;
      document.body.style.userSelect = 'none';
    }
  };

  const stopDrag = () => {
    if (isDragging.current) {
      isDragging.current = false;
      dragStarted.current = true;
    }
    document.body.style.userSelect = '';
  };

  const onDrag = (e) => {
    if (isDragging.current) {
      const newRightOffset = window.innerWidth
        - e.clientX
        - chatBarRef.current.offsetWidth / 2;
      const maxRightOffset = window.innerWidth - chatBarRef.current.offsetWidth;
      const minRightOffset = 0;
      if (newRightOffset >= minRightOffset && newRightOffset <= maxRightOffset) {
        setRightOffset(newRightOffset);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', onDrag);
    window.addEventListener('mouseup', stopDrag);
    return () => {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', stopDrag);
    };
  }, []);

  // Hide if on /messages page
  if (location.pathname === '/messages') {
    return null;
  }

  // Helper to add newly created chat to local list
  const addChatToChatListLocal = (newChat) => {
    setChatList(prev => [newChat, ...prev]);
  };

  return (
    <Box
      ref={chatBarRef}
      sx={{
        position: 'fixed',
        bottom: 0,
        right: rightOffset,
        width: isOpen ? '350px' : '200px',
        height: isOpen ? '400px' : '50px',
        backgroundColor: '#e9e9e9',
        color: '#000',
        transition: 'all 0.3s',
        overflow: 'hidden',
        borderRadius: '10px 10px 0 0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ccc',
        cursor: !isOpen ? 'move' : 'default',
        zIndex: 100,
      }}
      onMouseDown={!isOpen ? startDrag : null}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          backgroundColor: '#f5f5f5',
          cursor: 'move',
          borderBottom: '1px solid #ddd',
        }}
        onMouseDown={startDrag}
        onClick={toggleChatBar}
      >
        <Typography variant="h6">Messages</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={handleFullScreenClick}>
            <OpenInFull />
          </IconButton>
          <IconButton color="inherit" onClick={toggleChatBar}>
            {isOpen ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </Box>
      </Box>

      {/* Body */}
      {isOpen && (
        <Box
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            height: '340px',
            overflowY: 'hidden',
          }}
        >
          {selectedChat ? (
            // Render the actual chat
            <IndividualChat
              threadId={selectedChat.id}
              userName={selectedChat.otherUser}
              initialMessages={selectedChat.messages}
              onBack={handleBack}
              addChatToChatList={addChatToChatListLocal}
            />
          ) : (
            // Render the list of chats
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              {chatList.map((chat) => (
                <ChatCard
                  key={chat.id}
                  userName={chat.userName}
                  message={chat.message}
                  date={chat.date}
                  sender={chat.sender}
                  onClick={() => handleChatCardClick(chat)}
                />
              ))}
            </Box>
          )}

          {/* Only show Autocomplete if no individual chat is open */}
          {!selectedChat && (
            <Paper
              component="form"
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 10px',
                boxShadow: 'none',
                borderTop: '1px solid #ddd',
                position: 'sticky',
                bottom: 0,
                backgroundColor: '#fff',
                zIndex: 11000,
              }}
            >
              <Box sx={{ flex: 1, position: 'relative' }}>
                <Autocomplete
                  freeSolo
                  options={userSuggestions}
                  getOptionLabel={(option) => `${option.name} (${option.email})`}
                  onInputChange={handleInputChange}
                  onChange={handleUserSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search user"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        position: 'absolute',
                        bottom: '100%',
                        left: 0,
                        right: 0,
                        zIndex: 11000,
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Checkbox
                        icon={<span />}
                        checkedIcon={<span />}
                        checked={false}
                      />
                      <Typography variant="body1">
                        {`${option.name} (${option.email})`}
                      </Typography>
                    </li>
                  )}
                />
              </Box>
            </Paper>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ChatBar;
