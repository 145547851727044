import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosApiService } from '../../../utilities/api/axios';
import dayjs from 'dayjs';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const MostIssuedItemsChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const itemsChartRef = useRef(null);
  const [itemData, setItemData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [equipmentNames, setEquipmentNames] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const itemUsage = {};
        const equipmentSet = new Set();

        const fetchItemData = async (TL_name) => {
          const response = await axiosApiService.get('/inventory_issue_list/', {
            headers: {
              'TL': TL_name,
            },
          });
          return response.data;
        };

        const isWithinLast7Days = (issueDate) => {
          const issuedDate = dayjs(issueDate); // Parse the `issued_from` field
          const today = dayjs().endOf('day'); // Current date, end of the day
          const sevenDaysAgo = dayjs().subtract(7, 'day').startOf('day'); // 7 days before, start of the day
          
          // Check if the date is within the range (inclusive)
          return issuedDate.isAfter(sevenDaysAgo) && issuedDate.isBefore(today) ||
                 issuedDate.isSame(sevenDaysAgo, 'day') || issuedDate.isSame(today, 'day');
        };

        for (const [id, name] of Object.entries(tlMappings)) {
          const data = await fetchItemData(name);
          data.forEach(item => {
            if (item.pickup_ === "Yes" && isWithinLast7Days(item.issued_from)) {
              if (!itemUsage[name]) {
                itemUsage[name] = {};
              }
              if (!itemUsage[name][item.name]) {
                itemUsage[name][item.name] = 0;
              }
              itemUsage[name][item.name] += item.quantity;
              equipmentSet.add(item.name);
            }
          });
        }

        const labels = Object.values(tlMappings);
        const equipmentNames = Array.from(equipmentSet);

        const top5ItemData = equipmentNames.map(equipment => {
          return {
            label: equipment,
            data: labels.map(label => {
              const sortedItems = Object.entries(itemUsage[label] || {})
                .sort(([, a], [, b]) => b - a)
                .slice(0, 5);

              const item = sortedItems.find(([itemName]) => itemName === equipment);
              return item ? item[1] : 0;
            }),
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
          };
        });

        setLabels(labels);
        setItemData(top5ItemData);
        setEquipmentNames(equipmentNames);
      } catch (error) {
        console.error('Error fetching issued items data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  const renderChart = (context) => {
    const maxIssuedItems = Math.ceil(Math.max(...itemData.flatMap(dataset => dataset.data)) * 1.2);

    return new Chart(context, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: itemData,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: { stacked: true },
          y: { stacked: true, beginAtZero: true, max: maxIssuedItems, ticks: { stepSize: 1 } },
        },
      },
    });
  };

  useEffect(() => {
    if (itemsChartRef.current) itemsChartRef.current.destroy();
    if (chartRef.current) itemsChartRef.current = renderChart(chartRef.current.getContext('2d'));

    return () => {
      if (itemsChartRef.current) itemsChartRef.current.destroy();
    };
  }, [labels, itemData]);

  return (
    <>
      <Card
        sx={{
          borderRadius: '24px',
          backgroundColor: '#f9fafe',
          borderColor: '#d3d3d3',
          borderWidth: '1px',
          borderStyle: 'solid',
          padding: '16px',
          width: '90%',
          height: '336px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={-3}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#212121',
                textAlign: 'center',
                letterSpacing: 0,
              }}
            >
              Most Issued Items
            </Typography>
            <IconButton onClick={handleOpen} aria-label="Expand chart">
              <ExpandMoreIcon sx={{rotate:"-135deg",}} />
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#7a7a7a',
              marginTop: '12px',
              fontWeight: '500',
              textAlign: 'center',
              letterSpacing: 0,
            }}
          >
            Top 5 most issued items from each TL
          </Typography>
          {itemData.length > 0 && itemData.some(dataset => dataset.data.some(value => value > 0)) ? (
            <Box sx={{ height: '250px', width: '100%' }}>
              <canvas ref={chartRef} style={{ width: '100%' }} />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
              width="100%"
            >
              <img
                src={nodatafoundImage}
                alt="No Data Found"
                style={{ width: '200px', marginBottom: '16px' }}
              />
              <Typography variant="h5" color="textSecondary">
                No data available
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="expanded-chart-dialog-title"
        sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}
      >
        <DialogTitle id="expanded-chart-dialog-title">Expanded Most Issued Items Chart</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: '500px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <canvas
              ref={(dialogCanvas) => {
                if (dialogCanvas && open) {
                  renderChart(dialogCanvas.getContext('2d'));
                }
              }}
              style={{ width: '100%' }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MostIssuedItemsChart;
