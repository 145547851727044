import axiosAuthService from "../axios";

const PushNotificationAPIs = {

    SubscribeUserDevice: async function (values) {
        try {
            const response = await axiosAuthService.post('/auth/subscribe/', values);
            return response.data;
        } catch (error) {
            console.error('Error refreshing token:', error.response?.data || error.message);
            throw error;
        }
    },

    SendNotifications: async function (values) {
        try {
            const response = await axiosAuthService.post('/auth/send-notification/', values);
            return response.data;
        } catch (error) {
            console.error('Error refreshing token:', error.response?.data || error.message);
            throw error;
        }
    },
};

export default PushNotificationAPIs;
