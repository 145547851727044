import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const ActivityOfUsersChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const activityChartRef = useRef(null);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchData() {
      try {
        let activeCount = 0;
        let inactiveCount = 0;

        const fetchUserData = async (TL_name) => {
          const response = await axiosApiService.get('/auth/users/', {
            headers: {
              'TL': TL_name,
            },
          });
          return response.data;
        };

        for (const name of Object.values(tlMappings)) {
          const data = await fetchUserData(name);
          data.forEach(item => {
            if (item.is_active) {
              activeCount++;
            } else {
              inactiveCount++;
            }
          });
        }

        setActiveUsers(activeCount);
        setInactiveUsers(inactiveCount);
      } catch (error) {
        console.error('Error fetching user activity data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  const renderChart = (context) => {
    return new Chart(context, {
      type: 'doughnut',
      data: {
        labels: ['Active', 'Inactive'],
        datasets: [
          {
            data: [activeUsers, inactiveUsers],
            backgroundColor: ['#FF6384', '#36A2EB'],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  useEffect(() => {
    if (activityChartRef.current) activityChartRef.current.destroy();
    if (chartRef.current) activityChartRef.current = renderChart(chartRef.current.getContext('2d'));

    return () => {
      if (activityChartRef.current) activityChartRef.current.destroy();
    };
  }, [activeUsers, inactiveUsers]);

  return (
    <>
      <Card
        sx={{
          borderRadius: '24px',
          backgroundColor: '#f9fafe',
          borderColor: '#d3d3d3',
          borderWidth: '1px',
          borderStyle: 'solid',
          padding: '16px',
          width: '90%',
          height: '336px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={-3}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '22px',
                fontWeight: '700',
                color: '#212121',
                textAlign: 'center',
                letterSpacing: 0,
              }}
            >
              Activity of Users
            </Typography>
            <IconButton onClick={handleOpen} aria-label="Expand chart">
              <ExpandMoreIcon sx={{rotate:"-135deg"}}/>
            </IconButton>
          </Box>
          {activeUsers > 0 || inactiveUsers > 0 ? (
            <Box sx={{ height: '250px', marginTop: '4px' }}>
              <canvas ref={chartRef} style={{ width: '100%' }} />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
              width="100%"
            >
              <img
                src={nodatafoundImage}
                alt="No Data Found"
                style={{ width: '200px', marginBottom: '16px' }}
              />
              <Typography variant="h5" color="textSecondary">
                No data available
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="expanded-chart-dialog-title"
        sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}
      >
        <DialogTitle id="expanded-chart-dialog-title">Expanded User Activity Chart</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: '500px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <canvas
              ref={(dialogCanvas) => {
                if (dialogCanvas && open) {
                  renderChart(dialogCanvas.getContext('2d'));
                }
              }}
              style={{ width: '100%' }}
            /> */}
            <canvas
              ref={(dialogCanvas) => {
                if (dialogCanvas && open) {
                  new Chart(dialogCanvas.getContext('2d'), {
                    type: 'doughnut',
                    data: {
                      labels: ['Active', 'Inactive'],
                      datasets: [
                        {
                          data: [activeUsers, inactiveUsers],
                          backgroundColor: ['#FF6384', '#36A2EB'],
                        },
                      ],
                    },
                    options: {
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'right',
                          labels: {
                            font: {
                              size: 14,
                            },
                          },
                        },
                      },
                    },
                  });
                }
              }}
              style={{ width: '100%' }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActivityOfUsersChart;
