import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const TLStatsTable = ({ tlMappings }) => {
  const [tlStats, setTlStats] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const tlData = await Promise.all(
          Object.entries(tlMappings).map(async ([tlId, tlName]) => {
            const [
              usersResponse,
              inventoryIssuesResponse,
              purchaseResponse,
              reservationResponse,
              inventoryResponse,
              issueResponse,
              projectResponse,
            ] = await Promise.all([
              axiosApiService.get('/auth/users/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/inventory_issue_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/inventory_purchase_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/machine_reservation_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/admin_inventory_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/auth/report_user_issue_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/project/', {
                headers: {
                  'TL': tlName,
                },
              }),
            ]);

            const totalUsers = usersResponse.data.length;
            const totalAdminUsers = usersResponse.data.filter(user => user.is_admin).length;
            const totalInventoryIssues = inventoryIssuesResponse.data.length;
            const totalPurchased = purchaseResponse.data.length;
            const totalBooked = reservationResponse.data.reduce((acc, curr) => acc + curr.duration, 0).toFixed(2);

            // Calculate unique inventory items and total available stock
            const uniqueItems = inventoryResponse.data.length; // Count of unique inventory items
            const totalAvailable = inventoryResponse.data.reduce((acc, curr) => acc + curr.stock_available, 0);

            const totalIssues = issueResponse.data.length;
            const totalSolvedIssues = issueResponse.data.filter(issue => issue.solved).length;
            const totalProjects = projectResponse.data.length;

            return {
              name: tlName,
              totalUsers,
              totalAdminUsers,
              totalInventoryIssues,
              totalPurchased,
              totalBooked,
              // Updated format to show unique items with total available stock
              totalAvailable: `${uniqueItems} (${totalAvailable})`,
              totalIssues,
              totalSolvedIssues,
              totalProjects,
            };
          })
        );

        setTlStats(tlData);
      } catch (error) {
        console.error('Error fetching TL stats data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  return (
    <Paper sx={{
      borderRadius: '24px', 
      backgroundColor: '#f9fafe', 
      borderColor: '#d3d3d3', 
      borderWidth: '1px', 
      borderStyle: 'solid', 
      padding: '16px', 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      justifyContent: 'center', // Added center alignment
    }}>
      <Typography variant="h6" component="div" sx={{ 
        padding: '16px', 
        fontSize: '32px', 
        fontWeight: '700', 
        color: '#000', 
        textAlign: 'center', 
        letterSpacing: 0 
      }}>
        TL Stats
      </Typography>

      {tlStats.length > 0 ? (
        <TableContainer sx={{ width: '100%' }}>
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#fffae6' }}>
                <TableCell align="center">Name of item</TableCell>
                <TableCell align="center">No. of users</TableCell>
                <TableCell align="center">Admin users</TableCell>
                <TableCell align="center">Inventory issues</TableCell>
                <TableCell align="center">Inventory Purchases</TableCell>
                <TableCell align="center">Machine Bookings (Hrs)</TableCell>
                <TableCell align="center">Inventory Items Available</TableCell>
                <TableCell align="center">Issues Reported</TableCell>
                <TableCell align="center">Issues Solved</TableCell>
                <TableCell align="center">Projects Undergoing</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tlStats.map((row) => (
                <TableRow key={row.name} sx={{ border: '2px solid', borderColor: '#5a5a5a', borderStyle: 'solid' }}>
                  <TableCell align="center" sx={{ textDecoration: 'underline', color: '#151515', fontSize: '14px', fontWeight: '500' }}>{row.name}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalUsers}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalAdminUsers}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalInventoryIssues}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalPurchased}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalBooked}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalAvailable}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalIssues}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalSolvedIssues}</TableCell>
                  <TableCell align="center" sx={{ color: '#5a5a5a', fontSize: '14px' }}>{row.totalProjects}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="250px" width="100%">
          <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
          <Typography variant='h5' color="textSecondary" sx={{ textAlign: 'center' }}>
            No data available
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default TLStatsTable;
