// src/components/NavBar.js

import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { updateTL } from '../../redux/slices/userSlice';
import { addMessage, clearMessages } from '../../redux/slices/messagesSlice';
import { Badge, Box, Button } from '@mui/material';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationCard from './notification'; // Ensure correct path
import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';
import CreateTL from './CreateTL';
import { ImageBaseURL } from "../../utilities/api/axios";

// --- Rename or import your *global* WebSocket service:
import GlobalWebSocketService from '../../utilities/api/IMS/GlobalWebSocketService';
// or:
// import GlobalWebSocketService from '../../utilities/api/IMS/GlobalWebSocketService';

const NavBar = () => {
  const [notifi, setNotifi] = useState(false);
  const [tinkerLabs, setTinkerLabs] = useState([]);
  const [openCreateTL, setOpenCreateTL] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { access, user } = useSelector((state) => state.user.user);
  const unreadCount = useSelector(state => state.messages.unreadCount);
  const activeChatUser = useSelector(state => state.messages.activeChatUser);
  const messages = useSelector(state => state.messages.messages);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // If you want a ref to the service, you can keep it:
  const socketRef = useRef(null);

  const userAvatarUrl = user.profile_pic ? `${ImageBaseURL}${user.profile_pic}` : '';

  // Fetch Tinker Labs
  useEffect(() => {
    const fetchTinkerLabs = async () => {
      try {
        const labs = await TinkerLabListAPIs.TinkerLabsListGet();
        setTinkerLabs(labs);
      } catch (error) {
        console.error('Error fetching Tinker Labs data:', error);
      }
    };
    fetchTinkerLabs();
  }, [openCreateTL]);

  // GLOBAL WebSocket connection
//   useEffect(() => {
//     // 1) Request Notification permission if not granted
//     if (Notification.permission !== 'granted') {
//       Notification.requestPermission().then(permission => {
//         if (permission !== 'granted') {
//           console.warn('Permission for notifications was denied.');
//         }
//       });
//     }

//     // 2) Connect as soon as user info is available
//     GlobalWebSocketService.connect(access, user.username, user.username);
//     socketRef.current = GlobalWebSocketService;

//     // 3) Register a message callback
//     GlobalWebSocketService.onMessage((messageData) => {
//       console.log("Global WebSocket message received:", messageData);

//       if (messageData && messageData.message && messageData.sender) {
//         // Only show a notification if it's NOT from the currently active chat user
//         if (messageData.sender !== activeChatUser) {
//           const newNotification = {
//             avatar: "https://www.example.com/avatar.jpg",
//             message: `Message from ${messageData.sender}: ${messageData.message}`,
//             time: new Date().toLocaleTimeString() + " • Today",
//           };
//           setNotifications(prev => [...prev, newNotification]);
          
//           // Optionally, dispatch to Redux if you want all incoming messages stored
//           // dispatch(addMessage({ ... }));

//           // Show a browser Notification
//           showNotification(newNotification);
//         }
//       }
//     });

//     // 4) IMPORTANT: No disconnect here—this is a global service
//     //    It should remain open until user logs out.
//     return () => {
//       // If you want to close on logout, do it in your logout logic, not on unmount here.
//     };
//   }, [access, user.username, activeChatUser, dispatch]);

  // Show a desktop notification
//   const showNotification = (notification) => {
//     if (Notification.permission === 'granted') {
//       new Notification('New Message', {
//         body: notification.message,
//         icon: notification.avatar,
//       });
//     }
//   };

  useEffect(() => {
    setNotifications(messages);
  },[messages]);


  // Toggle in-app notification card (does NOT affect WebSocket)
  const handleNotificationToggle = () => {
    setNotifi(prev => !prev);
    if (!notifi) {
      // Optionally clear the stored messages when opening the panel
    //   dispatch(clearMessages());
    }
  };

  const handleNavigation = () => {
    navigate('/calendar');
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const handleTLChange = (event) => {
    const selectedTLName = event.target.value;
    dispatch(updateTL({ TL: selectedTLName }));
  };

  const handleCreateTLOpen = () => {
    setOpenCreateTL(true);
  };

  const handleCreateTLClose = () => {
    setOpenCreateTL(false);
  };

  const showProfileIcon =
    (user.is_org_admin && user.TL_name === "Org-Admin") ||
    !user.is_org_admin;

  return (
    <>
      <AppBar position="static" color="inherit" elevation={1}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {user?.is_org_admin ? (
              <Select
                value={user.TL_name || ''}
                onChange={handleTLChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select TL' }}
              >
                {tinkerLabs.map((lab) => (
                  <MenuItem key={lab.id} value={lab.name}>
                    {lab.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              user?.TL_name || ''
            )}
          </Typography>

          {user?.TL_name === 'Org-Admin' && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateTLOpen}
              sx={{ marginRight: '27px' }}
            >
              Create TL
            </Button>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '27px' }}>
            <IconButton color="inherit" onClick={handleNotificationToggle}>
              <Badge
                color="secondary"
                badgeContent={unreadCount}
                overlap="circular"
                invisible={unreadCount === 0}
              >
                <NotificationAddIcon />
              </Badge>
            </IconButton>
          </Box>

          {user.TL_name !== "Org-Admin" && (
            <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleNavigation}>
              <CalendarTodayIcon />
            </IconButton>
          )}

          {showProfileIcon && (
            <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleProfile}>
              <Avatar alt="User Avatar" src={userAvatarUrl} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Notification card */}
      {notifi && <NotificationCard notifications={notifications} />}

      {/* Create TL Dialog */}
      <CreateTL open={openCreateTL} handleClose={handleCreateTLClose} />
    </>
  );
};

export default NavBar;
