import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const ProjectsChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const projectChartRef = useRef(null);
  const [projectData, setProjectData] = useState({
    academic: [],
    research: [],
    personal: [],
    other: [],
  });
  const [labels, setLabels] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const projectCounts = {
          academic: [],
          research: [],
          personal: [],
          other: [],
        };
        const labels = Object.values(tlMappings);

        const fetchProjectData = async (TL_name) => {
          const response = await axiosApiService.get('/project/', {
            headers: {
              'TL': TL_name,
            },
          });
          return response.data;
        };

        for (const tlName of labels) {
          const projects = await fetchProjectData(tlName);
          let academicCount = 0;
          let researchCount = 0;
          let personalCount = 0;
          let otherCount = 0;

          projects.forEach((project) => {
            if (project.type === '1') {
              academicCount++;
            } else if (project.type === '2') {
              researchCount++;
            } else if (project.type === '3') {
              personalCount++;
            } else if (project.type === '4') {
              otherCount++;
            }
          });

          projectCounts.academic.push(academicCount);
          projectCounts.research.push(researchCount);
          projectCounts.personal.push(personalCount);
          projectCounts.other.push(otherCount);
        }

        setLabels(labels);
        setProjectData(projectCounts);
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  const renderChart = (context) => {
    const hasData = Object.values(projectData).some(dataset => dataset.some(value => value > 0));

    if (hasData) {
      return new Chart(context, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Academic Projects',
              data: projectData.academic,
              backgroundColor: 'rgba(54, 162, 235, 0.6)',
            },
            {
              label: 'Research Projects',
              data: projectData.research,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
            {
              label: 'Personal Projects',
              data: projectData.personal,
              backgroundColor: 'rgba(255, 206, 86, 0.6)',
            },
            {
              label: 'Other Projects',
              data: projectData.other,
              backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (projectChartRef.current) projectChartRef.current.destroy();
    if (chartRef.current) projectChartRef.current = renderChart(chartRef.current.getContext('2d'));

    return () => {
      if (projectChartRef.current) projectChartRef.current.destroy();
    };
  }, [labels, projectData]);

  return (
    <>
      <Card
        sx={{
          borderRadius: '24px',
          backgroundColor: '#f9fafe',
          borderColor: '#d3d3d3',
          borderWidth: 1,
          borderStyle: 'solid',
          width: '90%',
          height: 336,
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CardContent sx={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={-3}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#212121',
                textAlign: 'center',
                letterSpacing: 0,
              }}
            >
              Project Types
            </Typography>
            <IconButton onClick={handleOpen} aria-label="Expand chart">
              <ExpandMoreIcon sx={{rotate:"-135deg",}} />
            </IconButton>
          </Box>
          {Object.values(projectData).some(dataset => dataset.some(value => value > 0)) ? (
            <Box
              sx={{
                height: '300px',
                width: '100%',
                marginTop: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <canvas ref={chartRef} style={{ width: '100%' }} />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
              width="100%"
            >
              <img
                src={nodatafoundImage}
                alt="No Data Found"
                style={{ width: '200px', marginBottom: '16px' }}
              />
              <Typography variant="h5" color="textSecondary">
                No data available
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="expanded-chart-dialog-title"
        sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}
      >
        <DialogTitle id="expanded-chart-dialog-title">Expanded Project Chart</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: '500px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <canvas
              ref={(dialogCanvas) => {
                if (dialogCanvas && open) {
                  renderChart(dialogCanvas.getContext('2d'));
                }
              }}
              style={{ width: '100%' }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProjectsChart;
